import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import "./common/styles/layout.scss"
import { lazy, Suspense } from "react"
import LoadingSpinner from "./common/loading-spinner/LoadingSpinner"
import DeliveryPage from "./deliveries/DeliveryPage"

const NotFound = lazy(() => import("./common/not-found/NotFound"))
const googleLmfsUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_LMFS_API_KEY}&loading=async&markers=size:mid%7Ccolor:0xFFFF00&callback=initMap&libraries=journeySharing`
const script = document.createElement("script")
script.src = googleLmfsUrl
script.async = true
script.defer = true
document.body.appendChild(script)

function App() {
  return (
    <Suspense
      fallback={
        <div style={{ height: "calc(100vh - 112px" }}>
          <LoadingSpinner />
        </div>
      }
    >
      <Router>
        <Routes>
          <Route path="/404" element={<NotFound />} />
          <Route path="/:locale/:deliveryId/*" element={<DeliveryPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </Suspense>
  )
}

export default App
